import React from 'react';
import PageLayout from '../components/PageLayout';
import { FlintHillsPond } from '../images/Pictures';
import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material'
import { FaMinus, FaPlus } from 'react-icons/fa';
import GoogleMapImage from '../components/GoogleMapImage';
import { PAGE_TAGLINES } from '../constants';
import { NavLink } from 'react-router-dom';

const questionAndAnswerList = [ 
    {
        question: "Where are we located?",
        answer: [
            "605 Middle Creek Rd, Elmdale, KS 66850", 
            <div style={{display: 'flex', justifyContent: 'center', padding: '32px 0px'}}>
                <GoogleMapImage/>
            </div>
        ]
    },
   {
        question: "How can I learn more about the silo",
        answer: ["Visit our ", <NavLink to="/blog">blog page</NavLink>, " to learn about the process. Informational book coming soon"]
    },
   {
        question: "How close are the two units?",
        answer: "The Paw Print Cabin and the Owl's Nest Silo are about 300 feet away from each other. Each unit has its own private patio space without seeing or interacting with the other"
    },
   {
        question: "What is the policy for cancellation and rescheduled?",
        answer: "100% refund up to 14 days before arrival. 50% refund up to 7 days before arrival"
    },
   {
        question: "Do you have Wi-Fi",
        answer: "We do not have Wi-Fi. Take this time to unplug and enjoy the peaceful nature that is on our property"
    },
    { 
        question: "What kind of fireplaces do you have?", 
        answer: "We have gas fireplaces than run on propane creating a warm and inviting atmosphere without the hassle of a wood fire. You can adjust the temperature and brightness with a remote control and enjoy the realistic flames and logs." 
    }, 
    { 
        question: "Can we roast marshmallows at the properties?", 
        answer: "Of course! We provide fire pits near both the silo and the cabin, along with plenty of chopped wood for your convenience. You can bring your own marshmallows, graham crackers, and chocolate, or buy them at the nearby convenience store." 
    }, 
    { 
        question: "What kind of grills do you have for outdoor cooking?", 
        answer: "We have propane-powered grills that are easy to use and maintain. We also provide spare propane tanks on site, so you don't have to worry about running out of fuel. You can grill your favorite meats, veggies, and more on our spacious grills." 
    }, 
    { 
        question: "Is coffee available in the morning?", 
        answer: "We know how important coffee is for some of our guests, so we make sure to provide drip-coffee makers at both properties. You can also find coffee grounds in the cupboards, or bring your own if you prefer a different brand or flavor." 
    }, 
    { 
        question: "What are some fun activities we can do near the properties?", 
        answer: "One of the most popular activities is fishing at the nearby Middle Creek. Depending on the season, you can catch different kinds of fish, such as bass, catfish, crappie, and more. You will need to bring your own poles and bait, or rent them from a local shop." 
    }, 
    { 
        question: "How is the view of the night sky?", 
        answer: "One of the best things about staying at our properties is the amazing view of the stars. Since we are in a rural area, there is no light pollution to interfere with your stargazing. You can see constellations, planets, shooting stars, and even the Milky Way on a clear night." 
    }, 
    {
        question: "Are we allowed to swim in the creek?",
        answer: "Yes you can, but wading in the creek or any other activities in the water are done so at your own risk. Paw Print Cabin, LLC is not responsible for any accidents near or in the water."
    }
    
]
  
function FAQ() {
    const [expandedList, setExpandedList] = React.useState(questionAndAnswerList.map(() => false))

    function handleChange(index) {
        const newList = [...expandedList]
        newList[index] = !expandedList[index]
        setExpandedList([...newList])
    }

    return (
        <PageLayout headerImage={FlintHillsPond} overlayText={PAGE_TAGLINES.faq}>
            <div style={{padding: '64px 20vw'}}>
                {questionAndAnswerList.map((QNA, index) => (
                    <Accordion 
                        key={index} 
                        expanded={expandedList[index]} 
                        onChange={() => handleChange(index)}
                    >
                        <AccordionSummary 
                            expandIcon={expandedList[index] ? <FaMinus/> : <FaPlus/>}
                        >
                            <h2>{QNA.question}</h2>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>{QNA.answer}</p>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </PageLayout>
    )
};
  
export default FAQ;